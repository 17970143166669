<script lang="ts" setup>
import type { KeenSliderOptions, KeenSliderInstance, KeenSliderHooks } from "keen-slider";

const sliderActiveSlide = ref(0);

const sliderOptions: KeenSliderOptions = {
	loop: false,
	mode: "free-snap",
	breakpoints: {
		"(max-width: 767px)": {
			slides: {
				perView: "auto",
				spacing: 8
			}
		}
	},
	slides: { perView: "auto", spacing: 12 },
	created(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	},
	slideChanged(slider) {
		sliderActiveSlide.value = slider.track.details.abs;
	}
};
const isArrowDisabled = (slider: KeenSliderInstance<{}, {}, KeenSliderHooks> | undefined) => {
	if (!slider || typeof slider?.options?.slides !== "object" || typeof slider.options.slides?.perView !== "number") {
		return false;
	}
	const { perView } = slider.options.slides;

	return sliderActiveSlide.value + perView >= slider.slides.length || slider.track.details.slides.length <= perView;
};
</script>

<template>
	<ASlider v-slot="{ slider }" :options="sliderOptions">
		<AButtonGroup class="keen-nav">
			<AButton variant="secondary" data-tid="slider-prev" @click="slider?.prev()">
				<ASvg :class="{ 'arrow-disabled': sliderActiveSlide === 0 }" filled name="16/arrow-prev" />
			</AButton>
			<AButton variant="secondary" data-tid="slider-next" @click="slider?.next()">
				<ASvg
					:class="{
						'arrow-disabled': isArrowDisabled(slider)
					}"
					filled
					name="16/arrow-next"
				/>
			</AButton>
		</AButtonGroup>
		<slot />
	</ASlider>
</template>
<style lang="scss" scoped>
.keen-slider {
	display: flex;
	margin-top: gutter(-5.5);
	padding-top: gutter(8.5);
	@include media-breakpoint-down(xl) {
		overflow: visible;
	}
}

.keen-slider__slide {
	overflow: visible;

	@include media-breakpoint-down(lg) {
		min-width: 202px;
		max-width: 202px;
	}
}

.keen-nav {
	display: flex;
	position: absolute;
	right: 0;
	top: 0;

	&::before {
		content: "";
		display: block;
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 50%;
		transform: translate(-50%, 0);
		background: var(--cannes);
		opacity: 0.1;
	}

	@include media-breakpoint-down(xl) {
		display: none;
	}
}

.arrow-disabled {
	opacity: 0.25;
}
</style>
